import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import editor from "../../Assets/Projects/Hotelio.png";
import chatify from "../../Assets/Projects/Flyaway.png";
import bitsOfCode from "../../Assets/Projects/Aurora.png";

function Projects() {
  return (
    <Container fluid className="project-section">
     
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatify}
              isBlog={false}
              title="FlyAway"
              description="Travel friendly Booking Website build with react.js, Material-UI, and Firebase. Have features which allows user for booking flights, hotels as well as booking services. This Project was made during my mern stack course as part of my academics."
              ghLink="https://github.com/rakesh1259/sdp.git"
              demoLink="https://flyaway-frontend.netlify.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}
              isBlog={false}
              title="Aurora Aviations"
              description="Flight Booking Website build with Enterprise Application using Servlet , JSP, Bootstrap, and mySQL. Have features which allows user for booking flights, manage bookings as well as flight services. This Project was made during my enterprise programming course as part of my academics."
              ghLink="https://github.com/rakesh1259/"
              demoLink="/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={editor}
              isBlog={false}
              title="Hotelio"
              description="Hotel Booking Website build with Springboot and mySQL. Have features which allows user for booking rooms in hotels, manage bookings as well as hotel services. This Project was made during my java full stack course as part of my academics."
              ghLink="https://github.com/rakesh1259/Hotelio.git"
              demoLink="/"              
            />
          </Col>

         
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
